<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col
          md="5"
          sm="5"
          lg="5"
        >
          <v-form>
            <validation-provider
              v-slot="{ errors }"
              :name="$t('address-Line')"
              rules="required"
            >
              <v-text-field
                v-model="addressData.AddressLine"
                :label="$t('address-Line')"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              :name="$t('city')"
              rules="required"
            >
              <v-text-field
                v-model="addressData.City"
                :label="$t('city')"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>

            <v-row dense>
              <v-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('zip-code')"
                  rules="required"
                >
                  <v-text-field
                    v-model="addressData.ZipCode"
                    :label="$t('zip-code')"
                    type="number"
                    :error-messages="errors"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
              <v-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('state')"
                  rules="required"
                >
                  <v-text-field
                    v-model="addressData.State"
                    :label="$t('state')"
                    :error-messages="errors"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-select
              v-model="addressData.Country"
              :items="countriesList"
              :label="$t('country')"
              outlined
              dense
            />
            <v-radio-group
              v-model="addressData.AddressType"
              row
            >
              <v-radio
                label="Home Address"
                :value="1"
              />
              <v-radio
                label="Business Address"
                :value="2"
              />
            </v-radio-group>
          </v-form>
        </v-col>
        <v-col
          md="7"
          sm="5"
          lg="5"
        >
          <template>
            <l-map
              ref="map"
              :zoom="zoom"
              :style="{ height: 350 +'px', width: '365px', zIndex: 0 }"
              :center="{lat: addressData.Latitude, lng: addressData.Longitude}"
              @ready="onReady"
            >
              <l-control-layers position="topright" />
              <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                layer-type="base"
              />
              <l-control-scale
                position="bottomleft"
                :imperial="false"
                :metric="true"
              />
              <l-control position="bottomleft">
                {{ addressData.Latitude + ' ' + addressData.Longitude }}
              </l-control>
              <l-marker
                ref="marker"
                :lat-lng="{lat: addressData.Latitude, lng: addressData.Longitude}"
                :draggable="true"
                @dragend="onDragEnd"
              />
            </l-map>
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import countries from '../../../lib/Countries.js'
  import { LMap, LTileLayer, LControlScale, LControlLayers, LMarker, LControl } from 'vue2-leaflet'
  import 'leaflet/dist/leaflet.css'
  export default {
    components: {
      LMap,
      LTileLayer,
      LControlScale,
      LControlLayers,
      LMarker,
      LControl,
    },
    props: {
      addressData: {
        type: Object,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: {
          Latitude: 0,
          Longitude: 0,
        },
      },
    },
    data () {
      return {
        zoom: 15,
        tileProviders: [
          {
            name: 'Google Map',
            visible: false,
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            url: 'https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
          },
          {
            name: 'Openstreet Map',
            visible: true,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
          },
        ],
      }
    },
    computed: {
      countriesList: {
        get () {
          return countries.map(c => c.name)
        },
      },
    },
    created () {
    },
    methods: {
      onReady () {
        this.$refs.map.mapObject.invalidateSize()
      },
      onDragEnd (event) {
        const latLng = {
          latitude: this.$refs.marker.mapObject.getLatLng().lat,
          longitude: this.$refs.marker.mapObject.getLatLng().lng,
        }
        this.addressData.Latitude = latLng.latitude.toFixed(6)
        this.addressData.Longitude = latLng.longitude.toFixed(6)
      },
    },
  }
</script>

<style>

</style>
