var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"790px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('newWarehouse'))+" ")]),_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.close}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.stepperModel),callback:function ($$v) {_vm.stepperModel=$$v},expression:"stepperModel"}},[_c('v-stepper-step',{attrs:{"complete":_vm.stepperModel > 1,"step":"1","editable":""}},[_vm._v(" Warehouse Details "),_c('small',[_vm._v("Content must be filled")])]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-container',{staticClass:"ml-1 mr-6"},[_c('validation-provider',{attrs:{"name":_vm.$t('warehousename'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('warehousename'),"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.warehousename),callback:function ($$v) {_vm.warehousename=$$v},expression:"warehousename"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('contact-person'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('contact-person'),"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.contactPerson),callback:function ($$v) {_vm.contactPerson=$$v},expression:"contactPerson"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('contact-email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('contact-email'),"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.contactEmail),callback:function ($$v) {_vm.contactEmail=$$v},expression:"contactEmail"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('contact-phone-number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('contact-phone-number'),"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.contactPhone),callback:function ($$v) {_vm.contactPhone=$$v},expression:"contactPhone"}})]}}])}),_c('v-select',{attrs:{"label":"Status","items":_vm.verifiersFromRepo,"item-text":"ShortDescription","item-value":"LowValue","outlined":"","dense":""},model:{value:(_vm.statusId),callback:function ($$v) {_vm.statusId=$$v},expression:"statusId"}}),_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","width":"110px"},on:{"click":function($event){_vm.stepperModel = 2}}},[_vm._v(" Continue ")]),_c('v-btn',{staticClass:"canvas",attrs:{"text":"","rounded":"","width":"110px"},on:{"click":_vm.close}},[_vm._v(" Cancel ")])],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepperModel > 2,"step":"2"}},[_vm._v(" Address details "),_c('small',[_vm._v("Content must be filled")])]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('address-comp',{attrs:{"address-data":_vm.addressData}}),_c('v-btn',{staticClass:"canvas ml-4",attrs:{"rounded":"","text":"","width":"110px"},on:{"click":function($event){_vm.stepperModel--}}},[_vm._v(" Back ")])],1)],1)],1),(_vm.stepperModel === 2)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"secondary",attrs:{"rounded":"","text":"","width":"110px"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cancel ")]),_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")],1),_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","text":"","width":"110px"},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }