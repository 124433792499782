<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="790px"
  >
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        <h5 v-if="dialogType === 'editBranch'">
          {{ branchOffice.BranchOfficeName }}
        </h5>
        <h5 v-else>
          {{ $t('newBranchOffice') }}
        </h5>
        <v-icon
          color="white"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-stepper
          v-model="stepperModel"
          vertical
        >
          <v-stepper-step
            :complete="stepperModel > 1"
            step="1"
            :editable="editable"
          >
            Branch Details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-container class="ml-1 mr-6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('branch-office-name')"
                rules="required"
              >
                <v-text-field
                  v-model="branchOffice.BranchOfficeName"
                  :label="$t('branch-office-name')"
                  :error-messages="errors"
                  outlined
                  dense
                  class="mt-4"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-person')"
                rules="required"
              >
                <v-text-field
                  v-model="branchOffice.ContactPerson"
                  :label="$t('contact-person')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-email')"
                rules="required"
              >
                <v-text-field
                  v-model="branchOffice.ContactEmail"
                  :label="$t('contact-email')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-phone-number')"
                rules="required"
              >
                <v-text-field
                  v-model="phoneNumber"
                  :label="$t('contact-phone-number')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider
                :name="$t('assignRegionalOffice')"
                rules="required"
              >
                <v-select
                  v-model="branchOffice.RegionalOfficeId"
                  :label="$t('assignRegionalOffice')"
                  :items="selectableData.regionalOffices"
                  item-text="RegionalOfficeName"
                  item-value="Id"
                  outlined
                  dense
                />
              </validation-provider>
              <v-select
                v-model="branchOffice.Status"
                label="Status"
                :items="branchStatuses"
                item-text="ShortDescription"
                item-value="LowValue"
                dense
                outlined
              />
              <v-btn
                rounded
                class="primary"
                width="110px"
                @click="stepperModel = 2"
              >
                Continue
              </v-btn>
              <v-btn
                text
                rounded
                width="110px"
                class="canvas"
                @click="close"
              >
                Cancel
              </v-btn>
            </v-container>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepperModel > 2"
            step="2"
            :editable="editable"
          >
            Address details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <address-comp
              :address-data="addressData"
            />
            <v-btn
              rounded
              text
              width="110px"
              class="canvas ml-4"
              @click="stepperModel--"
            >
              Back
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="stepperModel === 2 || dialogType === 'editBranch'">
        <v-spacer />
        <v-btn
          rounded
          text
          class="secondary"
          width="110px"
          @click="close"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          text
          class="primary"
          width="110px"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import countries from '../../../lib/Countries.js'
  import AddressComp from '../component/AddressComp.vue'
  import { AsYouType } from 'libphonenumber-js'

  export default {
    components: {
      AddressComp,
    },
    props: {
      dialog: {
        type: Boolean,
      },
      dialogType: {
        type: String,
        default: '',
      },
      clickedBranchOffice: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        phoneNumber: null,
        stepperModel: 1,
        selectableData: {
          addresses: [],
          customers: [],
          regionalOffices: [],
        },
        addressId: -1,
        statusId: -1,
        branchOfficeId: -1,
        regionalOfficeId: -1,
        statuses: [
          {
            key: this.$t('active'),
            title: this.$t('active'),
            value: 1,
          },
          {
            key: this.$t('inactive'),
            title: this.$t('inactive'),
            value: 0,
          },
        ],
        customerId: 1,
      }
    },
    computed: {
      ...mapState({
        regionalOffices: (state) => state.regionaloffice.regionaloffices,
        userData: (state) => state.authentication.userData,
        selectedAddress: state => state.address.selectedAddress,
        branchStatuses: (entytiStatuses) => entytiStatuses.verifier.branchStatuses,
      }),
      countriesList: {
        get () {
          return countries.map(c => c.name)
        },
      },
      branchOffice: {
        get () {
          if (this.dialogType === 'editBranch') {
            return this.clickedBranchOffice
          } else return {}
        },
      },
      addressData: {
        get () {
          if (this.dialogType === 'editBranch') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.phoneNumber = this.clickedBranchOffice.ContactPhone
            return this.clickedBranchOffice.Address
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.branchOffice.Latitude = 42
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.branchOffice.Longitude = 21
            return this.branchOffice
          }
        },
      },
      editable: {
        get () {
          if (this.dialogType === 'editBranch') {
            return true
          } else return false
        },
      },
    },
    watch: {
      phoneNumber (val, oldVal) {
        if (val !== null && val !== oldVal) {
          this.phoneNumber = new AsYouType().input(val)
        }
      },
    },
    async mounted () {
      await this.getRegionalOffices()
    },
    methods: {
      async getRegionalOffices () {
        try {
          await this.$store.dispatch('regionaloffice/getRegionalOffices')
          this.selectableData.regionalOffices = this.regionalOffices
        } catch (err) {
        }
      },
      close () {
        this.$emit('branch-office-dialog-closed')
      },
      async save () {
        const body = {
          addressId: this.branchOffice.AddressId,
          customerId: this.userData.customerId,
          regionalOfficeId: this.branchOffice.RegionalOfficeId,
          status: this.branchOffice.Status,
          branchOfficeName: this.branchOffice.BranchOfficeName,
          contactPerson: this.branchOffice.ContactPerson,
          contactEmail: this.branchOffice.ContactEmail,
          contactPhone: this.phoneNumber.split(' ').join(''),
        }
        if (this.dialogType === 'editBranch') {
          body.id = this.branchOffice.Id
          body.customerId = this.branchOffice.CustomerId
          await this.$store.dispatch('address/updateAddress', this.addressData)
          await this.$store.dispatch('branchOffice/updateBranchOffice', body)
            .then(res => {
              if (res.resFlag) {
                this.$emit('successfully-edited-branch-office')
                this.close()
              }
            })
        } else {
          const addressBody = {
            AddressType: this.addressData.AddressType,
            AddressLine: this.addressData.AddressLine,
            ZipCode: this.addressData.ZipCode,
            City: this.addressData.City,
            State: this.addressData.State,
            Country: this.addressData.Country,
            Longitude: this.addressData.Latitude,
            Latitude: this.addressData.Longitude,
            CustomerId: this.userData.CustomerId,
          }
          await this.$store.dispatch('address/createAddress', addressBody).then((res) => {
            body.addressId = res.data.id
          })
          await this.$store.dispatch('branchOffice/createBranchOffice', body)
            .then(res => {
              if (res.resFlag) {
                this.$emit('successfully-added-branch-office', res.msg)
                this.close()
              }
            })
        }
      },
    },
  }
</script>

<style scoped>
.v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
.v-dialog > .v-card > .v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
</style>
