<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="790px"
  >
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        <h5>
          {{ $t('newDealer') }}
        </h5>
        <v-icon
          color="white"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-stepper
          v-model="stepperModel"
          vertical
        >
          <v-stepper-step
            :complete="stepperModel > 1"
            step="1"
            editable
          >
            Dealer Details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-container class="ml-1 mr-6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('dealerName')"
                rules="required"
              >
                <v-text-field
                  v-model="dealerName"
                  :label="$t('dealerName')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-person')"
                rules="required"
              >
                <v-text-field
                  v-model="contactPerson"
                  :label="$t('contact-person')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-email')"
                rules="required"
              >
                <v-text-field
                  v-model="contactEmail"
                  :label="$t('contact-email')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-phone-number')"
                rules="required"
              >
                <v-text-field
                  v-model="contactPhone"
                  :label="$t('contact-phone-number')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
              <v-select
                v-model="statusId"
                label="Status"
                :items="verifiersFromRepo"
                item-text="ShortDescription"
                item-value="LowValue"
                outlined
                dense
              />
              <v-btn
                rounded
                class="primary"
                width="110px"
                @click="stepperModel = 2"
              >
                Continue
              </v-btn>
              <v-btn
                text
                rounded
                width="110px"
                class="canvas"
                @click="close"
              >
                Cancel
              </v-btn>
            </v-container>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepperModel > 2"
            step="2"
          >
            Address details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <address-comp
              :address-data="addressData"
            />
            <v-btn
              rounded
              text
              width="110px"
              class="canvas ml-4"
              @click="stepperModel--"
            >
              Back
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="stepperModel === 2">
        <v-spacer />
        <v-btn
          rounded
          text
          class="secondary"
          width="110px"
          @click="close"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          text
          class="primary"
          width="110px"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import countries from '../../../lib/Countries.js'
  import AddressComp from '../component/AddressComp.vue'

  export default {
    components: {
      AddressComp,
    },
    props: {
      dialog: {
        type: Boolean,
      },
      dialogType: {
        type: String,
        default: '',
      },
      clickedDealer: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        stepperModel: 1,
        selectableData: {
          addresses: [],
          customers: [],
        },
        addressId: -1,
        statusId: -1,
        dealerId: -1,
        dealerName: '',
        contactPerson: '',
        contactEmail: '',
        contactPhone: '',
        statuses: [
          {
            key: this.$t('active'),
            title: this.$t('active'),
            value: 1,
          },
          {
            key: this.$t('inactive'),
            title: this.$t('inactive'),
            value: 0,
          },
        ],
        customerId: 1,
        addressData: {
          state: '',
          city: '',
          addressLine: '',
          zipCode: '',
          country: '',
          addressType: null,
          zoom: 15,
          markers: {
            lat: 42,
            long: 21,
          },
        },
      }
    },
    computed: {
      ...mapState({
        customers: (state) => state.customer.customers,
        addresses: (state) => state.address.addresses,
        userData: (state) => state.authentication.userData,
        selectedAddress: state => state.address.selectedAddress,
        verifiersFromRepo: (state) => state.verifier.verifiers,
      }),
      status () {
        return [
          { name: this.$t('active'), value: 1 },
          { name: this.$t('inactive'), value: 0 },
        ]
      },
      countriesList: {
        get () {
          return countries.map(c => c.name)
        },
      },
    },
    async mounted () {
      await this.getDetails()
    },
    methods: {
      async getDetails () {
        if (this.dialogType === 'editDealer') {
          this.addressId = this.clickedDealer.Address.Id
          this.dealerId = this.clickedDealer.Id
          this.dealerName = this.clickedDealer.Name
          this.customerId = this.clickedDealer.CustomerId
          this.contactPerson = this.clickedDealer.ContactPerson
          this.contactEmail = this.clickedDealer.ContactEmail
          this.contactPhone = this.clickedDealer.ContactPhone
          this.statusId = this.clickedDealer.Status === 'Active' ? 1 : 0
          this.addressData.state = this.clickedDealer.Address.State
          this.addressData.city = this.clickedDealer.Address.City
          this.addressData.addressLine = this.clickedDealer.Address.AddressLine
          this.addressData.zipCode = this.clickedDealer.Address.ZipCode
          this.addressData.country = this.clickedDealer.Address.Country
          this.addressData.addressType = this.clickedDealer.Address.AddressType
          this.addressData.markers.lat = this.clickedDealer.Address.Latitude
          this.addressData.markers.long = this.clickedDealer.Address.Longitude
          this.addressData.latitude = this.clickedDealer.Address.Latitude
          this.addressData.longitude = this.clickedDealer.Address.Longitude
        }
      },
      close () {
        this.$emit('dealer-dialog-closed')
      },
      async save () {
        const body = {
          addressId: this.addressId,
          customerId: this.userData.customerId,
          status: this.statusId,
          dealerName: this.dealerName,
          contactPerson: this.contactPerson,
          contactEmail: this.contactEmail,
          contactPhone: this.contactPhone,
        }
        if (this.dialogType === 'editDealer') {
          body.id = this.dealerId
          this.addressData.id = this.addressId
          this.addressData.customerId = this.customerId
          await this.$store.dispatch('address/updateAddress', this.addressData)
          await this.$store.dispatch('dealer/updateDealer', body)
            .then(res => {
              if (res.resFlag) {
                this.close()
              }
            })
        } else {
          this.createAddress()
          await this.$store.dispatch('dealer/createDealer', body)
            .then(res => {
              if (res.resFlag) {
                this.close()
              }
            })
        }
      },
      async createAddress () {
        const body = {
          AddressType: this.addressType,
          AddressLine: this.addressLine,
          ZipCode: this.zipCode,
          City: this.city,
          State: this.state,
          Country: this.country,
          Longitude: 41.01,
          Latitude: 20.96,
          CustomerId: this.userData.customerId,
        }
        await this.$store.dispatch('address/createAddress', body).then((res) => {
          this.addressId = res.data.id
        })
      },
    },
  }
</script>

<style scoped>
.v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
.v-dialog > .v-card > .v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
</style>
