<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="790px"
  >
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        <h5>
          {{ isItEdit ? $t('editUser') : $t('newUser') }}
        </h5>
        <v-icon
          color="white"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-stepper
          v-model="stepperModel"
          vertical
        >
          <v-stepper-step
            :complete="stepperModel > 1"
            step="1"
            editable
          >
            Personal Details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-container class="ml-1 mr-6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('first-name')"
                rules="required"
              >
                <v-text-field
                  v-model="user.Name"
                  :label="$t('first-name')"
                  :error-messages="errors"
                  outlined
                  dense
                  class="mt-4"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('surname')"
                rules="required"
              >
                <v-text-field
                  v-model="user.Surname"
                  :label="$t('surname')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('email')"
                rules="required"
              >
                <v-text-field
                  v-model="user.Email"
                  :label="$t('email')"
                  :error-messages="errors"
                  outlined
                  dense
                  :readonly="isItEdit"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('username')"
                rules="required"
              >
                <v-text-field
                  v-model="user.Username"
                  :label="$t('username')"
                  :error-messages="errors"
                  outlined
                  dense
                  :readonly="isItEdit"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('phone-number')"
                rules="required"
              >
                <v-text-field
                  v-model="phoneNumber"
                  :label="$t('phone-number')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
              <v-btn
                rounded
                color="primary"
                width="110px"
                @click="stepperModel = 2"
              >
                Continue
              </v-btn>
              <v-btn
                text
                rounded
                width="110px"
                class="canvas"
                @click="close"
              >
                Cancel
              </v-btn>
            </v-container>
          </v-stepper-content>

          <v-stepper-step
            :complete="stepperModel > 2"
            step="2"
            :editable="editable"
          >
            Address details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <address-comp
              :address-data="addressData"
            />
            <v-btn
              rounded
              color="primary"
              width="110px"
              class="mb-2 ml-4"
              @click="stepperModel++"
            >
              Continue
            </v-btn>
            <v-btn
              rounded
              text
              width="110px"
              class="canvas mb-2"
              @click="stepperModel--"
            >
              Back
            </v-btn>
          </v-stepper-content>
          <v-stepper-step
            :complete="stepperModel > 3"
            step="3"
            :editable="editable"
          >
            Role details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-container class="ml-1 mr-6">
              <validation-provider>
                <v-select
                  v-model="user.Role.Data"
                  :items="selectableData.roles"
                  item-text="DisplayName"
                  item-value="Data"
                  :label="$t('role')"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider>
                <v-select
                  v-model="statusId"
                  :items="userStatuses"
                  item-text="ShortDescription"
                  item-value="LowValue"
                  :label="$t('status')"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider
                :name="$t('assignWarehouse')"
                rules="required"
              >
                <v-select
                  v-if="user.Role.Data.name==='WAREHOUSE_ADMIN'"
                  v-model="warehouseId"
                  :label="$t('assignWarehouse')"
                  :items="selectableData.warehouses"
                  item-text="WarehouseName"
                  item-value="Id"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider
                :name="$t('assignDealer')"
                rules="required"
              >
                <v-select
                  v-if="user.Role.Data.name==='DEALER_ADMIN'"
                  v-model="dealerId"
                  :label="$t('assignDealer')"
                  :items="selectableData.dealers"
                  item-text="Name"
                  item-value="Id"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider
                :name="$t('assignRegionalOffice')"
                rules="required"
              >
                <v-select
                  v-if="user.Role.Data.name==='REGIONAL_ADMIN'"
                  v-model="regionalOfficeId"
                  :label="$t('assignRegionalOffice')"
                  :items="selectableData.regionalOffices"
                  item-text="RegionalOfficeName"
                  item-value="Id"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider
                :name="$t('assignBranchOffice')"
                rules="required"
              >
                <v-select
                  v-if="user.Role.Data.name==='BRANCH_ADMIN' || user.Role.Data.name==='BRANCH_STAFF'"
                  v-model="branchOfficeId"
                  :label="$t('assignBranchOffice')"
                  :items="selectableData.branchOffices"
                  item-text="BranchOfficeName"
                  item-value="Id"
                  outlined
                  dense
                />
              </validation-provider>
            </v-container>
            <!-- <v-btn
              text
              class="secondary"
              @click="stepperModel--"
            >
              Previous
            </v-btn> -->
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="stepperModel === 3 || dialogType === 'editUser'">
        <v-spacer />
        <v-btn
          rounded
          text
          class="secondary"
          width="110px"
          @click="close"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          text
          class="primary"
          width="110px"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import countries from '../../../lib/Countries.js'
  import AddressComp from '../component/AddressComp.vue'
  import { AsYouType } from 'libphonenumber-js'

  export default {
    components: {
      AddressComp,
    },
    props: {
      dialog: {
        type: Boolean,
      },
      dialogType: {
        type: String,
        default: '',
      },
      clickedUser: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        user: {
          Name: '',
          Surname: '',
          Email: '',
          Username: '',
          Role: {
            Data: {
              id: '',
              name: '',
            },
          },
        },
        phoneNumber: null,
        stepperModel: 1,
        steps: 2,
        selectableData: {
          roles: [],
          addresses: [],
          customers: [],
          warehouses: [],
          dealers: [],
          regionalOffices: [],
          branchOffices: [],
        },
        birthdate: '2021-04-07T08:45:09.637Z',
        addressId: -1,
        roleData: {},
        statusId: -1,
        warehouseId: -1,
        dealerId: -1,
        regionalOfficeId: -1,
        branchOfficeId: -1,
        customerId: null,
      }
    },
    computed: {
      ...mapState({
        roles: (state) => state.role.roles,
        customers: (state) => state.customer.customers,
        addresses: (state) => state.address.addresses,
        warehouses: (state) => state.warehouse.warehouses,
        dealers: (state) => state.dealer.dealers,
        regionalOffices: (state) => state.regionaloffice.regionaloffices,
        branchOffices: (state) => state.branchOffice.branchOffices,
        userData: (state) => state.authentication.userData,
        userStatuses: (entytiStatuses) => entytiStatuses.verifier.userStatuses,
      }),
      countriesList: {
        get () {
          return countries.map(c => c.name)
        },
      },
      addressData: {
        get () {
          if (this.dialogType === 'editUser') {
            return this.clickedUser.Address
          } else {
            return { Latitude: 42, Longitude: 21 }
          }
        },
      },
      isItEdit () {
        return this.dialogType === 'editUser'
      },
      editable: {
        get () {
          if (this.dialogType === 'editUser') {
            return true
          } else return false
        },
      },
    },
    watch: {
      steps (val) {
        if (this.e1 > val) {
          this.e1 = val
        }
      },
      phoneNumber (val, oldVal) {
        if (val !== null && val !== oldVal) {
          this.phoneNumber = new AsYouType().input(val)
        }
      },
    },
    async mounted () {
      if (this.isItEdit) {
        this.user = this.clickedUser
        this.phoneNumber = this.clickedUser.Phone
        this.branchOfficeId = this.clickedUser.BranchOfficeId
        this.regionalOfficeId = this.clickedUser.RegionalOfficeId
      }
      await this.getRoles()
      await this.getWarehouses()
      await this.getDealers()
      await this.getRegionalOffices()
      await this.getBranchOffices()
      if (this.isItEdit) {
        this.getCurrentStatus()
      }
    },
    methods: {
      getCurrentStatus () {
        const currentVerifier = this.userStatuses.filter(verifier => Number(verifier.LowValue) === this.clickedUser.Status)
        this.statusId = currentVerifier[0].LowValue
      },
      async getRegionalOffices () {
        try {
          await this.$store.dispatch('regionaloffice/getRegionalOffices')
          this.selectableData.regionalOffices = this.regionalOffices
        } catch (err) {
        }
      },
      async getDealers () {
        try {
          await this.$store.dispatch('dealer/getDealer')
          this.selectableData.dealers = this.dealers
        } catch (err) {
        }
      },
      async getRoles () {
        try {
          await this.$store.dispatch('role/getRole')
          this.selectableData.roles = this.roles
        } catch (err) {
        }
      },
      async getWarehouses () {
        try {
          await this.$store.dispatch('warehouse/getWarehouse')
          this.selectableData.warehouses = this.warehouses
        } catch (err) {
        }
      },
      async getBranchOffices () {
        try {
          await this.$store.dispatch('branchOffice/getBranchOffice')
          this.selectableData.branchOffices = this.branchOffices
        } catch (err) {
        }
      },
      close () {
        this.$emit('user-dialog-closed')
      },
      async save () {
        const body = {
          firstName: this.user.Name,
          lastName: this.user.Surname,
          email: this.user.Email,
          phone: this.phoneNumber.split(' ').join(''),
          username: this.user.Username,
          password: '123456789',
          gender: 'M',
          birthdate: this.user.Birthdate,
          roleId: this.user.Role.Data.id,
          addressId: this.dialogType === 'editUser' ? this.user.Address.Id : this.addressId,
          customerId: this.dialogType === 'editUser' ? this.clickedUser.Customer.Id : this.userData.customerId,
          status: this.statusId,
        }
        if (this.warehouseId !== -1) {
          body.warehouseId = this.warehouseId
        } else if (this.dealerId !== -1) {
          body.dealerId = this.dealerId
        } else if (this.regionalOfficeId !== -1) {
          body.regionalOfficeId = this.regionalOfficeId
        } else if (this.branchOfficeId !== -1) {
          body.branchOfficeId = this.branchOfficeId
        }
        if (this.dialogType === 'editUser') {
          body.id = this.user.Id
          this.addressData.id = this.addressData.Id
          this.addressData.customerId = this.addressData.CustomerId
          await this.$store.dispatch('address/updateAddress', this.addressData)
          await this.$store.dispatch('user/updateUser', body)
            .then(res => {
              if (res.resFlag) {
                if (this.user.Role.Data.name === 'BRANCH_ADMIN') {
                  const assignBody = {
                    branchOfficeId: this.branchOfficeId,
                    userId: this.user.Id,
                  }
                  this.$store.dispatch('branchofficeuser/assignuseridbranchofficeid', assignBody)
                }
                if (this.user.Role.Data.name === 'REGIONAL_ADMIN') {
                  const assignBody = {
                    regionalOfficeId: this.regionalOfficeId,
                    userId: this.user.Id,
                  }
                  this.$store.dispatch('regionalofficeuser/assignuseridregionalofficeid', assignBody)
                }
                this.$emit('successfully-edited-user')
                this.close()
              }
            })
        } else {
          const newAddress = {
            AddressType: this.addressData.AddressType,
            AddressLine: this.addressData.AddressLine,
            ZipCode: this.addressData.ZipCode,
            City: this.addressData.City,
            State: this.addressData.State,
            Country: this.addressData.Country,
            Latitude: this.addressData.Latitude,
            Longitude: this.addressData.Longitude,
            CustomerId: this.dialogType === 'editUser' ? this.clickedUser.Customer.Id : this.userData.customerId,
          }
          if (this.userData.roleData.roleName === 'SYSTEM_MASTER') {
            body.customerId = null
          } // TODO find a better solution here
          await this.$store.dispatch('address/createAddress', newAddress).then((response) => {
            if (response.resFlag) {
              body.addressId = response.data.id
            }
          })
          await this.$store.dispatch('user/createUser', body)
            .then(res => {
              if (res.resFlag) {
                this.$emit('successfully-added-user', res.msg)
                this.close()
              }
            })
        }
      },
      async createAddress () {
        const body = {
          AddressType: this.addressData.AddressType,
          AddressLine: this.addressData.AddressLine,
          ZipCode: this.addressData.ZipCode,
          City: this.addressData.City,
          State: this.addressData.State,
          Country: this.addressData.Country,
          Latitude: this.addressData.Latitude,
          Longitude: this.addressData.Longitude,
          CustomerId: this.dialogType === 'editUser' ? this.clickedUser.Customer.Id : this.userData.customerId,
        }

        await this.$store.dispatch('address/createAddress', body).then((res) => {
          this.addressId = res.data.id
        })
      },
      nextStep (n) {
        if (n === this.steps) {
          this.e1 = 1
        } else {
          this.e1 = n + 1
        }
      },
    },

  }
</script>

<style scoped>
  .v-card__title {
      background: #16578f;
      color: #ffffff;
      height: 40px;
      padding: 5px 20px;
  }
  .v-dialog > .v-card > .v-card__title {
      background: #16578f;
      color: #ffffff;
      height: 40px;
      padding: 5px 20px;
  }
</style>
