<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="790px"
  >
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        <h5>
          {{ isItEdit ? $t('editCustomer') : $t('newCustomer') }}
        </h5>
        <v-icon
          color="white"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-stepper
          v-model="stepperModel"
          vertical
        >
          <v-stepper-step
            :complete="stepperModel > 1"
            step="1"
            editable
          >
            Organization Details
            <small>Content must be filled</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-container class="ml-1 mr-6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('customerName')"
                rules="required"
              >
                <v-text-field
                  v-model="clickedCustomer.Name"
                  :label="$t('customerName')"
                  :error-messages="errors"
                  outlined
                  dense
                  class="mt-4"
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-person')"
                rules="required"
              >
                <v-text-field
                  v-model="clickedCustomer.ContactPerson"
                  :label="$t('contact-person')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-phone-number')"
                rules="required"
              >
                <v-text-field
                  v-model="clickedCustomer.ContactPhone"
                  :label="$t('contact-phone-number')"
                  :error-messages="errors"
                  outlined
                  dense
                  :readonly="isItEdit"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('contact-email')"
                rules="required"
              >
                <v-text-field
                  v-model="clickedCustomer.ContactEmail"
                  :label="$t('contact-email')"
                  :error-messages="errors"
                  outlined
                  dense
                  :readonly="isItEdit"
                />
              </validation-provider>
              <validation-provider>
                <v-select
                  v-model="clickedCustomer.Status"
                  :items="customerStatuses"
                  item-text="ShortDescription"
                  item-value="LowValue"
                  :label="$t('status')"
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider>
                <v-select
                  v-show="!isItEdit"
                  v-model="clickedCustomer.AdminId"
                  :items="filteredUsers"
                  item-text="FullName"
                  item-value="Id"
                  :label="$t('assignCustomer')"
                  outlined
                  dense
                />
              </validation-provider>
              <v-switch
                v-model="switch1"
                :label="`${ switch1 === true ? 'IsOrganization' : 'IsPerson' }`"
              />
              <v-btn
                rounded
                color="primary"
                width="110px"
                @click="stepperModel = 2"
              >
                Continue
              </v-btn>
              <v-btn
                text
                rounded
                width="110px"
                class="canvas"
                @click="close"
              >
                Cancel
              </v-btn>
            </v-container>
          </v-stepper-content>
          <v-stepper-step
            :complete="stepperModel > 2"
            step="2"
            editable
          >
            Address details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <address-comp
              :address-data="addressData"
            />
            <div class="d-flex justify-space-between">
              <v-btn
                rounded
                text
                width="110px"
                class="canvas mb-2"
                @click="stepperModel--"
              >
                Back
              </v-btn>
              <v-btn
                rounded
                color="primary"
                width="110px"
                class="mb-2 ml-4"
                @click="save"
              >
                Save
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapState } from 'vuex'
  import AddressComp from '../component/AddressComp.vue'
  export default {
    components: {
      AddressComp,
    },
    props: {
      dialog: {
        type: Boolean,
      },
      dialogType: {
        type: String,
        default: '',
      },
      clickedCustomer: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        customerAdminId: -1,
        addressId: -1,
        customersWithoutOrg: [],
        switch1: false,
        stepperModel: 1,
        steps: 2,
        statusId: 1,
        filteredUsers: [],
        selectableData: {
          roles: [],
          addresses: [],
          customers: [],
          warehouses: [],
          dealers: [],
          regionalOffices: [],
          branchOffices: [],
          statuses: [
            {
              key: -1,
              title: 'Pending',
            },
            {
              key: 1,
              title: 'Active',
            },
            {
              key: 0,
              title: 'Inactive',
            },
          ],
        },
      }
    },
    computed: {
      ...mapState({
        customerStatuses: (entytiStatuses) => entytiStatuses.verifier.customerStatuses,
      }),
      addressData: {
        get () {
          if (this.dialogType === 'editCustomer') {
            return this.clickedCustomer.Address
          } else {
            return { Latitude: 42, Longitude: 21 }
          }
        },
      },
      customer: {
        get () {
          if (this.dialogType === 'editCustomer') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.phoneNumber = this.clickedCustomer.Phone
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.clickedCustomer
          } else {
            return {
              Role: {
                Data: {
                  name: null,
                },
              },
            }
          }
        },
      },
      isItEdit () {
        return this.dialogType === 'editCustomer'
      },
    },
    async mounted () {
      const res = await this.$store.dispatch('user/getNotAssociatedCustomerAdmins')
      this.filteredUsers = res
    },
    methods: {
      close () {
        this.$emit('customer-dialog-closed')
      },
      async  save () {
        const body = {
          customerName: this.clickedCustomer.Name,
          addressId: this.addressId,
          customerAdminId: this.clickedCustomer.AdminId,
          contactPerson: this.clickedCustomer.ContactPerson,
          contactPhone: this.clickedCustomer.ContactPhone,
          contactEmail: this.clickedCustomer.ContactEmail,
          isMasterOrganization: this.switch1,
          status: this.clickedCustomer.Status,
        }
        if (this.isItEdit) {
          body.id = this.clickedCustomer.Id
          body.addressId = this.customer.AddressId
          this.addressData.customerId = this.addressData.CustomerId
          await this.$store.dispatch('address/updateAddress', this.addressData)
          await this.$store.dispatch('customer/updateCustomer', body)
            .then(res => {
              if (res.resFlag) {
                this.close()
              }
            })
        } else {
          const newAddress = {
            AddressType: this.addressData.AddressType,
            AddressLine: this.addressData.AddressLine,
            ZipCode: this.addressData.ZipCode,
            City: this.addressData.City,
            State: this.addressData.State,
            Country: this.addressData.Country,
            Latitude: this.addressData.Latitude,
            Longitude: this.addressData.Longitude,
          }
          await this.$store.dispatch('address/createAddress', newAddress).then((response) => {
            if (response.resFlag) {
              body.addressId = response.data.id
            }
          })
          await this.$store.dispatch('customer/createCustomer', body)
            .then(res => {
              if (res.resFlag) {
                this.close()
              }
            })
        }
      },
    },
  }
</script>

<style scoped>
.v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
.v-dialog > .v-card > .v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
</style>
