<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="900px"
  >
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        <h5 v-if="dialog === 'editAddress'">
          {{ $t('editAddress') }}
        </h5>
        <h5 v-if="dialog === 'addAddress'">
          {{ $t('addAddress') }}
        </h5>
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-spacer />
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row dense>
            <v-container>
              <v-row>
                <v-col
                  md="5"
                >
                  <v-form>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('address-Line')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="selectedAddress.addressLine"
                        :label="$t('address-Line')"
                        :error-messages="errors"
                        outlined
                        dense
                      />
                    </validation-provider>

                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('city')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="selectedAddress.city"
                        :label="$t('city')"
                        :error-messages="errors"
                        outlined
                        dense
                      />
                    </validation-provider>

                    <v-row dense>
                      <v-col md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('zip-code')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="selectedAddress.zipCode"
                            :label="$t('zip-code')"
                            type="number"
                            :error-messages="errors"
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-col>
                      <v-col md="6">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('state')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="selectedAddress.state"
                            :label="$t('state')"
                            :error-messages="errors"
                            outlined
                            dense
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-select
                      v-model="selectedAddress.country"
                      :items="countriesList"
                      :label="$t('country')"
                      outlined
                      dense
                    />
                    <v-radio-group
                      v-model="selectedAddress.addressType"
                      row
                    >
                      <v-radio
                        label="Home Address"
                        :value="1"
                      />
                      <v-radio
                        label="Business Address"
                        :value="2"
                      />
                    </v-radio-group>
                  </v-form>
                </v-col>
                <v-col
                  md="7"
                >
                  <template>
                    <l-map
                      ref="map"
                      :zoom="zoom"
                      :style="{ height: 350 +'px', width: '100%', zIndex: 0 }"
                      :center="{lat: selectedAddress.markers.lat, lng: selectedAddress.markers.long}"
                      @ready="onReady"
                    >
                      <l-control-layers position="topright" />
                      <l-tile-layer
                        v-for="tileProvider in tileProviders"
                        :key="tileProvider.name"
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"
                      />
                      <l-control-scale
                        position="bottomleft"
                        :imperial="false"
                        :metric="true"
                      />
                      <l-control position="bottomleft">
                        {{ selectedAddress.markers.lat + ' ' + selectedAddress.markers.long }}
                      </l-control>
                      <l-marker
                        ref="marker"
                        :lat-lng="{lat: selectedAddress.markers.lat, lng: selectedAddress.markers.long}"
                        :draggable="true"
                        @dragend="onDragEnd"
                      />
                    </l-map>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer />
        <v-btn
          rounded
          text
          class="secondary"
          width="110px"
          @click="close"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          text
          class="primary"
          width="110px"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import countries from '../../../lib/Countries.js'
  import { mapState } from 'vuex'
  import { LMap, LTileLayer, LControlScale, LControlLayers, LMarker, LControl } from 'vue2-leaflet'
  import 'leaflet/dist/leaflet.css'

  export default {
    components: {
      LMap,
      LTileLayer,
      LControlScale,
      LControlLayers,
      LMarker,
      LControl,
    },
    props: {
      dialog: {
        type: String,
        default: '',
      },
      clickedAddress: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        tileProviders: [
          {
            name: 'Google Map',
            visible: false,
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            url: 'https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
          },
          {
            name: 'Openstreet Map',
            visible: true,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
          },
        ],
        zoom: 15,
        selectedAddress: {
          id: -1,
          addressLine: '',
          city: '',
          country: '',
          state: '',
          zipCode: -1,
          customerId: -1,
          addressType: 2,
          markers: {
            lat: 42,
            long: 21,
          },
        },
      }
    },
    computed: {
      ...mapState({
        userData: (state) => state.authentication.userData,
      }),
      countriesList: {
        get () {
          return countries.map(c => c.name)
        },
      },
    },
    watch: {
    },
    async mounted () {
      this.assignAddress()
    },
    methods: {
      onReady () {
        this.$refs.map.mapObject.invalidateSize()
      },
      onDragEnd (event) {
        const latLng = {
          latitude: this.$refs.marker.mapObject.getLatLng().lat,
          longitude: this.$refs.marker.mapObject.getLatLng().lng,
        }
        this.selectedAddress.markers.lat = latLng.latitude.toFixed(6)
        this.selectedAddress.markers.long = latLng.longitude.toFixed(6)
      },
      assignAddress () {
        this.selectedAddress.id = this.clickedAddress.id
        this.selectedAddress.addressLine = this.clickedAddress.addressLine
        this.selectedAddress.city = this.clickedAddress.city
        this.selectedAddress.country = this.clickedAddress.country
        this.selectedAddress.state = this.clickedAddress.state
        this.selectedAddress.zipCode = this.clickedAddress.zipCode
        this.selectedAddress.customerId = this.userData.customerId
        this.selectedAddress.markers.lat = this.clickedAddress.latitude
        this.selectedAddress.markers.long = this.clickedAddress.longitude
      },
      close () {
        this.$emit('address-updated')
      },
      async save () {
        const body = this.selectedAddress
        this.selectedAddress.latitude = this.selectedAddress.markers.lat
        this.selectedAddress.longitude = this.selectedAddress.markers.long
        delete this.selectedAddress.markers
        await this.$store.dispatch('address/updateAddress', body)
          .then(res => {
            if (res.resFlag) {
              this.close()
            }
          })
      },
    },
  }
</script>

<style scoped>
  .v-card__title {
      background: #16578f;
      color: #ffffff;
      height: 40px;
      padding: 5px 20px;
  }
  .v-dialog > .v-card > .v-card__title {
      background: #16578f;
      color: #ffffff;
      height: 40px;
      padding: 5px 20px;
  }
</style>
