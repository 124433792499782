<template>
  <div
    class="d-flex justify-space-between"
  >
    <v-card
      width="48%"
    >
      <v-card-title
        class="canvas"
      >
        <h5>{{ $t('details') }}</h5>
        <v-spacer />
        <span class="float-right">
          <v-icon
            v-if="editPermission"
            small
            color="primary"
            @click="edit()"
          >mdi-pencil</v-icon>
        </span>
      </v-card-title>
      <v-divider />
      <v-list
        dense
        class="overflow-y-auto"
      >
        <v-list-item
          v-if="type === 'users'"
          three-line
        >
          <v-list-item-content>
            <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('name') }}: <span class="float-right mt-2">{{ details.FullName }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('username') }}: <span class="float-right mt-2">{{ details.Username }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ details.Email }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-alpha-r-box px-2 py-1" /> {{ $t('roleToDisplay') }}: <span class="float-right mt-2">{{ details.Role.DisplayName }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ details.Phone }}</span></v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon class="mdi mdi-home-map-marker px-2 py-1" />
              {{ $t('addressToDisplay') }}:
              <span class="float-right mt-2">{{ details.FormattedAddress }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ verifier.ShortDescription }}</span></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="type === 'stores'"
          three-line
        >
          <v-list-item-content>
            <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('branchOfficeName') }}: <span class="float-right mt-2">{{ details.BranchOfficeName }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ details.ContactPerson }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ details.ContactEmail }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ details.ContactPhone }}</span></v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon class="mdi mdi-home-map-marker px-2 py-1" />
              {{ $t('addressToDisplay') }}:
              <span class="float-right mt-2">{{ details.FormattedAddress }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ verifier.ShortDescription }}</span></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="type === 'warehouses'"
          three-line
        >
          <v-list-item-content>
            <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('name') }}: <span class="float-right mt-2">{{ details.WarehouseName }} </span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ details.ContactPerson }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ details.ContactEmail }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ details.ContactPhone }}</span></v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon class="mdi mdi-home-map-marker px-2 py-1" />
              {{ $t('addressToDisplay') }}:
              <span class="float-right mt-2">{{ details.FormattedAddress }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ verifier.ShortDescription }}</span></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="type === 'dealers'"
          three-line
        >
          <v-list-item-content>
            <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('name') }}: <span class="float-right mt-2">{{ details.Name }} </span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ details.ContactPerson }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ details.ContactEmail }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ details.ContactPhone }}</span></v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon class="mdi mdi-home-map-marker px-2 py-1" />
              {{ $t('addressToDisplay') }}:
              <span class="float-right mt-2">{{ details.FormattedAddress }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ verifier.ShortDescription }}</span></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="type === 'customers'"
          three-line
        >
          <v-list-item-content>
            <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('name') }}: <span class="float-right mt-2">{{ details.Name }} </span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ details.ContactPerson }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ details.ContactEmail }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ details.ContactPhone }}</span></v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon class="mdi mdi-home-map-marker px-2 py-1" />
              {{ $t('addressToDisplay') }}:
              <span class="float-right mt-2">{{ details.FormattedAddress }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ verifier.ShortDescription }}</span></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="type === 'regions'"
          three-line
        >
          <v-list-item-content>
            <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('name') }}: <span class="float-right mt-2">{{ details.RegionalOfficeName }} </span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ details.ContactPerson }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ details.ContactEmail }}</span></v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ details.ContactPhone }}</span></v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-icon class="mdi mdi-home-map-marker px-2 py-1" />
              {{ $t('addressToDisplay') }}:
              <span class="float-right mt-2">{{ details.FormattedAddress }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ verifier.ShortDescription }}</span></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-card
      v-if="type === 'warehouses'"
      width="48%"
    >
      <v-card-title
        class="card-title"
        style="padding: 5px 20px; height: 40px;"
      >
        <span>{{ $t('users') }}</span>
      </v-card-title>
      <v-row no-gutters>
        <v-col>
          <v-data-table
            :headers="wareHouseUserHeaders"
            :items="wareHouseUsers"
            dense
            fixed-header
            :search="searchUsers"
            :footer-props="{
              itemsPerPageText: '',
              itemsPerPageOptions: []
            }"
          >
            <template v-slot:top>
              <v-text-field
                v-model="searchUsers"
                label="Search Users"
                class="px-6 py-3"
                dense
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <edit-address
      v-if="addressDialog"
      :dialog="dialogType"
      :clicked-address="selectedAddress"
      @address-updated="dialogClosed"
    />
    <manage-regional-office
      v-if="regionDialog"
      :dialog="regionDialog"
      :dialog-type="dialogType"
      :clicked-regional-office="details"
      @manage-region-dialog-closed="dialogClosed"
      @successfully-edited-regional-office="regionEditedSuccessfully"
    />
    <manage-branch-office
      v-if="branchDialog"
      :dialog="branchDialog"
      :dialog-type="dialogType"
      :clicked-branch-office="details"
      @branch-office-dialog-closed="dialogClosed"
      @successfully-edited-branch-office="branchEditedSuccessfully"
    />
    <manage-user
      v-if="dialogEdit"
      :dialog="dialogEdit"
      :dialog-type="dialogType"
      :clicked-user="details"
      @user-dialog-closed="dialogClosed"
      @successfully-edited-user="userEditedSuccessfully"
    />
    <manage-warehouse
      v-if="wareHouseDialog"
      :dialog="wareHouseDialog"
      :dialog-type="dialogType"
      :clicked-warehouse="details"
      @ware-house-dialog-closed="dialogClosed"
    />
    <manage-dealer
      v-if="dealerDialog"
      :dialog="dealerDialog"
      :dialog-type="dialogType"
      :clicked-dealer="details"
      @dealer-dialog-closed="dialogClosed"
    />
    <manage-customer
      v-if="customerDialog"
      :dialog="customerDialog"
      :dialog-type="dialogType"
      :clicked-customer="details"
      @customer-dialog-closed="dialogClosed"
    />
  </div>
</template>
<script>
  import ManageUser from '../Dialogs/ManageUser.vue'
  import EditAddress from '../Dialogs/EditAddressDialog.vue'
  import ManageBranchOffice from '../Dialogs/ManageBranchOffice.vue'
  import ManageWarehouse from '../Dialogs/ManageWarehouse.vue'
  import ManageDealer from '../Dialogs/ManageDealer.vue'
  // import EditRegion from '../Dialogs/EditRegionalOffice.vue'
  import ManageRegionalOffice from '../Dialogs/ManageRegionalOffice.vue'
  import ManageCustomer from '../Dialogs/ManageCustomer.vue'
  import { checkPermission } from '@/router/permissions'
  import { mapState } from 'vuex'

  export default {
    name: 'DetailsCard',
    components: {
      ManageUser,
      EditAddress,
      ManageBranchOffice,
      ManageWarehouse,
      ManageDealer,
      // EditRegion,
      ManageRegionalOffice,
      ManageCustomer,
    },
    props: {
      type: {
        type: String,
        default: function () {
          return ''
        },
      },
      details: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data: () => ({
      dialogType: null,
      dialogEdit: false,
      dealerDialog: false,
      branchDialog: false,
      addressDialog: false,
      wareHouseDialog: false,
      regionDialog: false,
      customerDialog: false,
      clickedWarehouse: {
        addressId: -1,
        contactEmail: '',
        contactPerson: '',
        contactPhone: '',
        customerId: -1,
        id: -1,
        status: -1,
        warehouseName: '',
      },
      wareHouseUsers: [],
      searchUsers: '',
      selectedUser: {},
      selectedAddress: {},
      verifier: {},
    }),
    computed: {
      wareHouseUserHeaders () {
        return [
          { text: this.$t('firstname'), value: 'firstName' },
          { text: this.$t('lastname'), value: 'lastName' },
          { text: this.$t('phone-number'), value: 'phone' },
          { text: this.$t('email'), value: 'email' },
        ]
      },
      ...mapState({
        chosenAddress: (state) => state.address.selectedAddress,
        userData: (state) => state.authentication.userData,
        chosenVerifier: (state) => state.verifier.selectedVerifierByDomainAndValue,
      }),
      editPermission () {
        return checkPermission(this.userData.roleData.roleName, this.type, 'edit')
      },
    },
    async mounted () {
      await this.getVerifier()
    },
    methods: {
      branchEditedSuccessfully () {
        this.$emit('refresh-branch-offices')
      },
      regionEditedSuccessfully () {
        this.$emit('refresh-regional-offices')
      },
      userEditedSuccessfully () {
        this.$emit('refresh-users')
      },
      async editAddress () {
        if (this.type === 'user') {
          await this.$store.dispatch('address/getAddressById', this.details.Address.Id)
          Object.assign(this.selectedAddress, this.chosenAddress)
        } else {
          await this.$store.dispatch('address/getAddressById', this.details.AddressId)
          Object.assign(this.selectedAddress, this.chosenAddress)
        }
        this.dialogType = 'editAddress'
        this.addressDialog = true
      },
      edit () {
        if (this.type === 'users') {
          this.dialogType = 'editUser'
          this.dialogEdit = true
        } else if (this.type === 'stores') {
          this.dialogType = 'editBranch'
          this.branchDialog = true
        } else if (this.type === 'warehouses') {
          this.dialogType = 'editWarehouse'
          this.wareHouseDialog = true
        } else if (this.type === 'dealers') {
          this.dialogType = 'editDealer'
          this.dealerDialog = true
        } else if (this.type === 'regions') {
          this.dialogType = 'editRegion'
          this.regionDialog = true
        } else if (this.type === 'customers') {
          this.dialogType = 'editCustomer'
          this.customerDialog = true
        }
      },
      dialogClosed () {
        this.dialogEdit = false
        this.addressDialog = false
        this.branchDialog = false
        this.wareHouseDialog = false
        this.dealerDialog = false
        this.regionDialog = false
        this.customerDialog = false
      },
      async getVerifier () {
        switch (this.type) {
          case 'users':
            try {
              const body = {
                domain: 'USER_STATUS',
                lowValue: this.details.Status,
              }
              await this.$store.dispatch('verifier/getVerifiersForDomainAndValue', body)
              this.verifier = this.chosenVerifier
            } catch (err) {
            }
            break

          case 'regions':
            try {
              const body = {
                domain: 'REGION_STATUS',
                lowValue: this.details.Status,
              }
              await this.$store.dispatch('verifier/getVerifiersForDomainAndValue', body)
              this.verifier = this.chosenVerifier
            } catch (err) {
            }
            break

          case 'stores':
            try {
              const body = {
                domain: 'BRANCH_STATUS',
                lowValue: this.details.Status,
              }
              await this.$store.dispatch('verifier/getVerifiersForDomainAndValue', body)
              this.verifier = this.chosenVerifier
            } catch (err) {
            }
            break

          case 'warehouses':
            try {
              const body = {
                domain: 'WAREHOUSE_STATUS',
                lowValue: this.details.Status,
              }
              await this.$store.dispatch('verifier/getVerifiersForDomainAndValue', body)
              this.verifier = this.chosenVerifier
            } catch (err) {
            }
            break

          case 'dealers':
            try {
              const body = {
                domain: 'DEALER_STATUS',
                lowValue: this.details.Status,
              }
              await this.$store.dispatch('verifier/getVerifiersForDomainAndValue', body)
              this.verifier = this.chosenVerifier
            } catch (err) {
            }
            break
          case 'customers':
            try {
              const body = {
                domain: 'CUSTOMER_STATUS',
                lowValue: this.details.Status,
              }
              await this.$store.dispatch('verifier/getVerifiersForDomainAndValue', body)
              this.verifier = this.chosenVerifier
            } catch (err) {
            }
            break

          default:
            break
        }
      },
    },
  }
</script>
<style scoped>
.cardTitleText{
font-weight: normal;
color: white;
}
.v-dialog > .v-card > .v-card__title {
        background: #F3F4F6;
        color: #000;
        height: 40px;
        padding: 5px 20px;
    }
</style>
