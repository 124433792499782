<template>
  <v-container>
    <v-card>
      <v-card-title class="card-title">
        <h4>{{ $t('customers') }}</h4>
        <v-spacer />
        <v-btn
          color="primary"
          rounded
          small
          dark
          @click="addClick"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="mainHeaders"
        :items="filteredCustomers"
        :items-per-page="-1"
        :search="search"
        :footer-props="footerProps"
        :single-expand="true"
        :expanded.sync="expanded"
        dense
        sort-by="Id"
        item-key="Id"
        fluid
        fixed-header
        style="cursor:pointer"
        class="elevation-1"
        @click:row="expand"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="3"
                offset="8"
              >
                <v-select
                  v-model="filterCustomerStatus"
                  :items="statuses"
                  item-text="title"
                  item-value="key"
                  dense
                  class="mt-6"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
            <manage-customer
              v-if="dialog"
              :dialog="dialog"
              @customer-dialog-closed="dialogClosed"
              @successfully-added-user="addedCustomer"
            />
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length">
            <details-card
              :type="'customers'"
              :details="detailedCustomer"
            />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
  import { mapGetters, mapState } from 'vuex'
  import DetailsCard from '../Cards/DetailsCard.vue'
  import ManageCustomer from '../Dialogs/ManageCustomer.vue'
  export default {
    components: {
      DetailsCard,
      ManageCustomer,
    },
    data: () => ({
      dialog: false,
      search: '',
      loading: true,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      filterCustomerStatus: 'All',
      customers: [],
      verifiers: [],
      expanded: [],
      statuses: [
        {
          key: 'All',
          title: 'All',
        },
        {
          key: 1,
          title: 'Active',
        },
        {
          key: 0,
          title: 'Inactive',
        },
      ],
    }),
    computed: {
      ...mapState({
        customersFromRepo: (state) => state.customer.customers,
        detailedCustomer: (state) => state.customer.selectedCustomer,
        verifiersFromRepo: (state) => state.verifier.verifiers,
        customerStatuses: (entytiStatuses) => entytiStatuses.verifier.customerStatuses,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      mainHeaders () {
        let result = []
        result = [
          { text: this.$t('Id'), value: 'Id', align: 'start' },
          { text: this.$t('name'), value: 'Name' },
          { text: this.$t('email'), value: 'ContactEmail' },
          { text: this.$t('phone-number'), value: 'ContactPhone' },
          { text: this.$t('status'), value: 'StausForDisplay' },
        ]
        return result
      },
      filteredCustomers () {
        switch (this.filterCustomerStatus) {
          case 'All':
            return this.customers
          case 1:
            return this.customers.filter(c => c.Status === 1)
          case 0:
            return this.customers.filter(c => c.Status === 0)
          default:
            return this.customers
        }
      },
    },
    async mounted () {
      await this.getCustomers()
    },
    methods: {
      getUsers () {
        this.$store.dispatch('user/getUser')
      },
      addClick () {
        this.$nextTick(() => {
          this.dialog = true
        })
      },
      dialogClosed () {
        this.dialogEdit = false
        this.dialog = false
        this.getCustomers()
      },
      addedCustomer (msg) {
        this.alertEnabled = true
        this.alertMsg = msg
        this.alertType = 'success'
      },
      async getCustomers () {
        this.loading = true
        await this.$store.dispatch('customer/getCustomer')
          .then(c => {
            this.customers = this.customersFromRepo
            this.customers.forEach(c => {
              c.StausForDisplay = this.getVerifierShortDescription(this.customerStatuses, c.Status)
            })
            this.loading = false
            this.getUsers()
          })
      },
      async expand (item) {
        await this.$store.dispatch('customer/getCustomerById', item.Id)
        if (item === this.expanded[0]) this.expanded = []
        else this.expanded = [item]
      },
    },

  }
</script>
